import React from "react";

export default class ZtlIcon extends React.Component {
  render() {
    return (
      <svg width="46" height="36" viewBox="0 0 46 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M31.7225 25.9104V5.72798H36.8043V25.9104C36.8043 29.0001 37.978 30.133 41.2328 30.133H45.3708L43.4833 35.1429H41.2328C38.2447 35.1429 35.885 34.3219 34.2634 32.7797C32.6419 34.3219 30.2821 35.1429 27.2941 35.1429C24.1932 35.1429 21.769 34.2587 20.1442 32.6013C19.5057 33.18 18.7689 33.6662 17.9494 34.0582C16.9448 34.5387 15.8952 34.8399 14.8501 35.0065C14.4852 35.0647 14.1433 35.1025 13.8307 35.1238C13.6272 35.1377 13.4668 35.1429 13.3555 35.1429H0.944218L7.47795 18.3957H0.142822L2.03035 13.3859H14.8772L8.34346 30.133H13.3511C13.643 30.1148 13.8321 30.0939 14.0388 30.061C14.6272 29.9672 15.2111 29.7996 15.7311 29.5509C17.0654 28.9126 17.7821 27.8883 17.7841 25.9819C17.784 25.9681 17.7787 25.1093 17.7682 23.4055L17.8259 0.857143L22.9077 0.861284C22.9077 5.75211 22.9065 9.92694 22.9041 13.3859L22.8991 18.3957C22.8985 18.8337 22.8979 19.2526 22.8972 19.6523C22.8937 21.7423 22.8893 23.3102 22.884 24.3573C22.8814 24.8842 22.8785 25.2804 22.8754 25.548C22.8737 25.6886 22.872 25.7961 22.87 25.8747C22.8687 25.925 22.8675 25.975 22.8662 26.0247C22.8952 29.028 24.0798 30.133 27.2941 30.133C30.5489 30.133 31.7225 29.0001 31.7225 25.9104V5.72798H36.8043V25.9104"
          fill="#222A35"
        />
        <path d="M21.6021 13.4286H26.2359L24.343 18.4286H21.5972L21.6021 13.4286Z" fill="#222A35" />
        <path d="M18.8378 18.4286H14.204L16.0969 13.4286H18.8428L18.8378 18.4286Z" fill="#222A35" />
        <path
          d="M26.6846 13.4286H28.1037L26.2105 18.4286H24.7864L26.6846 13.4286ZM28.5791 13.4286H31.0197L29.1265 18.4286H26.6809L28.5791 13.4286Z"
          fill="#FFA900"
        />
      </svg>
    );
  }
}
