import React from "react";
import styled from "styled-components";

export default function Loading() {
    return (
        <Frame>
            <svg className="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                <circle cx="170" cy="170" r="160" stroke="#E2007C" />
                <circle cx="170" cy="170" r="135" stroke="#404041" />
                <circle cx="170" cy="170" r="110" stroke="#E2007C" />
                <circle cx="170" cy="170" r="85" stroke="#404041" />
            </svg>
        </Frame>
    );
}

const Frame = styled.div`
    margin-top: 10px;
    height: 60px;
    width: 60px;
    animation-name: opac;
    animation-duration: 0.7s;
    opacity: 0;
    animation-fill-mode: forwards;

    .loader {
        max-width: 15rem;
        width: 100%;
        height: auto;
        stroke-linecap: round;
    }

    circle:nth-of-type(1) {
        fill: none;
        stroke-width: 3.5;
        animation-name: preloader;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        transform-origin: 170px 170px;
        will-change: transform;
        stroke-dasharray: 550;
        animation-delay: 0.15s;
    }
    circle:nth-of-type(2) {
        fill: none;
        stroke-width: 3.5;
        transform-origin: 170px 170px;
        will-change: transform;
        stroke-dasharray: 500;
        animation-name: preloader;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        animation-delay: 0.3s;
    }
    circle:nth-of-type(3) {
        fill: none;
        stroke-width: 3.5;
        transform-origin: 170px 170px;
        will-change: transform;
        stroke-dasharray: 450;
        animation-name: preloader;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        animation-delay: 0.45s;
    }
    circle:nth-of-type(4) {
        fill: none;
        stroke-width: 3.5;
        transform-origin: 170px 170px;
        will-change: transform;
        stroke-dasharray: 300;
        animation-name: preloader;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }
    animation-delay: 0.6s;

    @keyframes preloader {
        50% {
            transform: rotate(360deg);
        }
    }

    @keyframes opac {
        0% {
            opacity: 0.01;
        }
        100% {
            opacity: 1;
        }
    }
`;
