import React from "react";
import styled from "styled-components";
import { PRIMARY_BACKGROUND_COLOR } from "./styles/colors";
import LoginCard from "./components/login-card";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const App: React.FC = () => {
    return (
        <Router>
            <Frame>
                <Switch>
                  <Route path="/username-password" component={LoginCard} />
                  <Route render={() => <div>404 - Path not found</div>} />
                </Switch>
            </Frame>
        </Router>
    );
};

const Frame = styled.div`
    height: 100vh;
    width: 100vw;
    background-color: ${PRIMARY_BACKGROUND_COLOR};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export default App;
