import queryString from "query-string";
import React, { useState } from "react";
import styled from "styled-components";
import { SNOW, PRIMARY_BUTTON_COLOR, WEAK_FONT_COLOR } from "../../styles/colors";
import ZtlIcon from "../../icons/ztl-icon";
import Loading from "./Loading";
import axios from "axios";

const localhostFallback = "oidc.ztl.test";
const LOCALHOST = "localhost";

const getOidcRedirectUrl = (host: string) => {
  if (host.indexOf(LOCALHOST) !== -1) {
    return localhostFallback;
  }
  return `https://${host
    .split(".")
    .map((el, i) => (i === 0 ? "oidc" : el))
    .join(".")}`;
};

async function handleSubmit(
  e: React.FormEvent<HTMLFormElement>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
  username: string,
  password: string
) {
  e.preventDefault();
  setLoading(true);
  try {
    const { state } = queryString.parse(window.location.search);
    await axios.post("/api/username-password/login", { username, password, state });

    const oidcUrl = getOidcRedirectUrl(window.location.host);
    window.location.replace(oidcUrl + "/callback/username-password-provider?" + queryString.stringify({ state }));
    setTimeout(() => setLoading(false), 10000);
  } catch (err) {
    console.log("err");
    console.log(err.response);
    setTimeout(() => setLoading(false), 1500);
    if (err.response && err.response.status === 401) return setTimeout(() => setError("Could not authorize the user"), 1500);
    return setTimeout(() => setError(err.message), 1500);
  }
}

const App: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  return (
    <Card onSubmit={e => handleSubmit(e, setLoading, setErrorMsg, username, password)}>
      <Circle>
        <ZtlIcon />
      </Circle>
      <div style={{ height: 20, marginBottom: 10 }}>
        {errorMsg ? <P style={{ color: "red", fontSize: 12 }}>{errorMsg}</P> : ""}
      </div>
      <div style={{ width: "50%" }}>
        <P>Username</P>
      </div>
      <InputField value={username} onChange={e => setUsername(e.target.value)} type="text" />
      <div style={{ width: "50%" }}>
        <P>Password</P>
      </div>
      <InputField value={password} onChange={e => setPassword(e.target.value)} type="password" />
      <div style={{ height: 100 }}>
        {loading ? (
          <Loading />
        ) : (
          <Button isDisabled={username === "" || password === ""} type="submit">
            Logg inn
          </Button>
        )}
      </div>
    </Card>
  );
};

const Circle = styled.div`
  height: 75px;
  width: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  border-radius: 40px;
  border: 1px solid #eee;
`;

const P = styled.p`
  font-size: 12px;
  font-weight: 300;
  color: ${WEAK_FONT_COLOR};
`;

type ButtonProps = {
  isDisabled: boolean;
};
const Button = styled.button<ButtonProps>`
  background-color: ${props => (props.isDisabled ? "#eee" : PRIMARY_BUTTON_COLOR)};
  border: none;
  font-weight: 500;
  color: ${SNOW};
  border-radius: 4px;
  height: 40px;
  min-width: 100px;
  border-radius: 20px;
  margin: 20px;
  margin-top: 30px;
  font-size: 12px;
  pointer-events: ${props => (props.isDisabled ? "none" : "all")};

  transition: all 0.3s;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;

const InputField = styled.input`
  height: 22px;
  padding: 8px;
  width: 50%;
  border-radius: 4px;
  border: 1px solid #eee;
  outline: none;
  margin: 10px;
  margin-top: 0px;
  font-size: 14px;
`;

const Card = styled.form`
  width: 400px;
  height: 450.410196625px;
  background-color: ${SNOW};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default App;
